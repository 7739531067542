import React, { useMemo, type ElementType } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from './parts/icon';
import { Text } from './parts/text';
import { V2ButtonContext } from './v2-button.context';
import type { V2ButtonProps } from './v2-button.types';

import S from './v2-button.styles';

type AllowedElements = 'a' | 'button' | 'react-router-link';

type V2ButtonOwnProps<E extends AllowedElements> = {
  as?: E;
  resetStyles?: React.CSSProperties;
} & V2ButtonProps;

type BtnProps<E extends AllowedElements> = V2ButtonOwnProps<E> &
  Omit<
    React.ComponentProps<E extends 'react-router-link' ? typeof RouterLink : E>,
    keyof V2ButtonOwnProps<E> | 'disabled'
  >;

/**
 * V2Button is a polymorphic component that can render different HTML elements or a React Router Link.
 * The `as` prop determines which element to render. It supports 'a', 'button', and 'react-router-link'.
 * If `as` is 'react-router-link', it renders a React Router Link component.
 * If `as` is not provided, it defaults to rendering a 'button' element.
 * The component also forwards any additional props to the rendered element.
 */
const V2Button = <E extends AllowedElements = 'button'>(props: BtnProps<E>) => {
  const {
    children,
    as = 'button',
    buttonSize = 'auto',
    variant = 'primary',
    direction = 'row',
    isDisabled = false,
    isStretchOnSmallScreens = false,
    isAlwaysStretch = false,
    isPaddingDisabled = false,
    resetStyles = {},
    buttonStyles = {},
    className,
    ...componentProps
  } = props;
  const contextValue = useMemo(() => ({ buttonSize, variant }), [buttonSize, variant]);
  const Component: ElementType = as === 'react-router-link' ? RouterLink : as;

  return (
    <S.ResetStyles
      isStretchOnSmallScreens={isStretchOnSmallScreens}
      style={resetStyles}
      isAlwaysStretch={isAlwaysStretch}
    >
      <Component
        {...componentProps}
        tabIndex={-1}
        disabled={isDisabled}
        style={{ height: '100%', ...props.style }}
        className={className}
      >
        <S.Button
          buttonSize={buttonSize}
          variant={variant}
          direction={direction}
          isDisabled={isDisabled}
          isPaddingDisabled={isPaddingDisabled}
          isAlwaysStretch={isAlwaysStretch}
          isStretchOnSmallScreens={isStretchOnSmallScreens}
          style={buttonStyles}
          tabIndex={0}
        >
          <V2ButtonContext.Provider value={contextValue}>{children}</V2ButtonContext.Provider>
        </S.Button>
      </Component>
    </S.ResetStyles>
  );
};

V2Button.Text = Text;
V2Button.Icon = Icon;

export { V2Button };
